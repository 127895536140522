























import {namespace} from "vuex-class";

export interface LanguageInterface {
  locale: string,
  flagKey: string,
}

import {Component, Prop, Vue} from 'vue-property-decorator';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import {applicationStoreMutations} from "@/store/application.store";

const ApplicationStore = namespace('application');

@Component({})
export default class LanguageSelectComponent extends Vue {

  @Prop({default: true})
  public darkMode!: boolean;

  @ApplicationStore.Mutation(applicationStoreMutations.SET_LOCALE)
  public setCurrentLocale!: (locale: string) => void;

  availableLanguages: LanguageInterface[] = [{
    locale: 'de', flagKey: 'DE'
  }, {
    locale: 'en', flagKey: 'GB'
  }];

  _selectedLanguage!: LanguageInterface;

  get selectedLanguage(): LanguageInterface {
    if (!this._selectedLanguage) {
      return this.availableLanguages.find(language => language.locale === this.$i18n.locale)!;
    }
    return this._selectedLanguage;
  }

  set selectedLanguage(value: LanguageInterface) {
    this._selectedLanguage = value!;
    this.$i18n.locale = value.locale;
    this.setCurrentLocale(value.locale);
  }

  public getFlag(country: string) {
    return getUnicodeFlagIcon(country);
  }
}
